.projects__project{
}
.projects__project__header{
    background: #2a283e;
    height: 20rem;
    width: 100%;
    position: relative;
    padding-right: 6rem;
    &:before {
        content: '';
        position: absolute;
        bottom: 0; right: 0;
        border-top: 5rem solid #2a283e;
        border-right: 10rem solid #191825;
        width: 0;
    }
}
.projects__project__header__top{
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 0 3rem;
    align-items: flex-end;
    height: 10rem;
    padding-bottom: 1rem;
    &:after{
        position: absolute;
        bottom: 0;
        content: '';
        transform: translateX(-3rem);
        width: 100%;
        height: 0.2rem;
        background: #E90064;
        display: block;
    }
}
.projects__project__header__top__title{
    color: #E90064;
    font-size: 5rem;
    font-weight: 400;
    font-family: 'Prompt', sans-serif;
    max-height: 5rem;
    line-height: 5rem;
}
.projects__project__header__top__date{
    color: #E90064;
    font-size: 2rem;
    line-height: 2rem;
    font-weight: 400;
    font-family: 'Prompt', sans-serif;
}
.projects__project__header__links{
    display: flex;
    gap: 6rem;
    padding: 0 4rem;
}
.projects__project__header__links__link{
    text-decoration: none;
    color: #E90064;
    font-size: 1.6rem;
    font-family: 'Prompt', sans-serif;
    border-bottom: 2px solid #E90064;
    position: relative;
    display: flex;
    transition: 0.3s ease-in-out;
    span{
        opacity: 0;
        position: absolute;
        top: 0;
        left: -2.6rem;
        aspect-ratio: 1/1;
        border-top: 2.6rem solid #E90064;
        border-left: 2.6rem solid #2a283e;
        width: 0;
        transition: 0.3s ease-in-out;
       &:last-of-type{
            border-bottom: 2.6rem solid #E90064;
            border-top: none;
            left: auto;
            right: -2.6rem;
            transform: rotate(180deg);
       }
    }
    &:before{
        bottom: 47%;
        left: -31px;
        position: absolute;
        content: "";
        width: 36px;
        transform: rotate(45deg);
        border: 1px solid #E90064;
    }
    &:after{
        bottom: 47%;
        right: -31px;
        position: absolute;
        content: "";
        width: 36px;
        transform: rotate(-45deg);
        border: 1px solid #E90064;
    }
    &:hover{
        span{
            opacity: 1;
        }
        background: #E90064;
        color: #2a283e;
    }
}

.projects__project__main{
    width: 100%;
    height: 100%;
    display: flex;
}
.projects__project__main__content{
    width: 100%;
    min-height: 100%;
    background: #2a283e;
    display: flex;
    padding: 5rem;
    padding-top: 0;
    justify-content: space-between;
}
.projects__project__main__content__description{
    font-size: 2rem;
    font-weight: 400;
    font-family: 'Prompt', sans-serif;
    color: #E90064;
    line-height: 3rem;
}
.projects__project__main__content__gallery{
    position: relative;
    width: 70rem;
    aspect-ratio: 16/9;
    border: 2px solid #E90064;
    border-radius: 3px;
    .swiper-slide:not(.swiper-slide-active){
        opacity: 0;
        transition: 0.3s ease-in-out;
    }
    .swiper{
        max-height: 100%;
        overflow: visible;
    }
    .swiper-wrapper{
        aspect-ratio: 16/9;
    }
    .swiper-button-next, .swiper-button-prev{
        z-index: 800;
        position: absolute;
        top: 50%;
        width: 2.5rem;
        height: 6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        border: none;
        border: 2px solid #E90064;
        &::after{
            font-weight: 600;
            font-size: 1.8rem;
            color: #E90064;
        }
        &:hover{
            background: #E90064;
            &::after{
                color: #2a283e;
            }
        }
        &:nth-of-type(2){
            left: 0;
            transform: translateX(-100%);
            border-right: none;
        }
        &:last-of-type{
            right: 0;
            transform: translateX(100%);
            border-left: none;
        }
    }
    .swiper-button-disabled{
        display: none;
    }
}
.projects__project__main__content__gallery__img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.projects__project__main__tags{
    min-width: 10rem;
    width: 10rem;
    height: 100%;
}
.projects__project__main__tags__figure{
    position: relative;
    width: 100%;
    aspect-ratio: 1/1;
    padding: 2rem;
    border-radius: 50%;
    &::after{
        content: '';
        position: absolute;
        background: #E90064;
        width: 1.8rem;
        height: 2px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}
.projects__project__main__tags__figure__img{
    object-fit: contain;
    width: 100%;
    height: 100%;
}