.screen{
    width: 100vw;
    min-height: 100vh;
    display: flex;
    background: #191825;
}

.layout{
    width: 8rem;
    min-height:  100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.layout__navigation{
    display: flex;
    flex-direction: column;

}
.layout__navigation__item{
    position: relative;
    width: 8rem;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: 0.3s ease-in-out;
    > i{
        color: #B3005E;
        transition: 0.3s ease-in-out;
    }
    
    &:after{
        content: '';
        width: 0.4rem;
        height: 100%;
        background: transparent;
        position: absolute;
        left: 0;
        transition: 0.3s ease-in-out;
    }
    &--selected{
        &:after{
            background: #B3005E;
        }
    }
    &:hover{
        background: #B3005E;
        > i{
            color: #191825;
        }
    }
}
.layout__footer{
    font-size: 1.2rem;
    color: #3a3838;
}

.outlet{
    width: 100%;
    height: 100%;
}