.about{

}
.about__header{
    background: #2a283e;
    width: 100%;
    height: 30rem;
    box-shadow: inset 0 0 3rem rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: flex-end;
    padding-bottom: 1rem;
    padding: 0 5rem;
    gap: 5rem;
}
.about__header__figure{
}
.about__header__figure__img{
    fill: #191825;
    svg{
        height: 25rem;
        transform: translateY(1rem);
    }
}
.about__header__title{
    color: #E90064;
    font-size: 6ch;
    font-weight: 400;
    font-family: "Prompt", sans-serif;
    max-height: 6rem;
    line-height: 6rem;
}

.about__main{

}