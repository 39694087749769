.home{
    max-width: 1840px;
    overflow: hidden;
}
.home__wrapper{
    width: 100%;
    height: 100vh;
    padding: 30vh 25vw;
}
.home__wrapper__timeline{
    border: 1px solid #E90064;
    color: #E90064;
    height: 100%;
}
.home__wrapper__timeline{
    .swiper{
        height: 100%;
        overflow: visible;
    }
    .swiper-slide{
        height: 100%;
    
    }
}