.projects{
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 6rem;
    padding: 6rem;
    padding-right: 14rem;
}

.projects__header{
    height: 6rem;
    position: relative;
    &::before{
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background: #E90064;

        top: 50%;
        transform: translateY(-50%);
        left: 0;
    }
}
.projects__header__icon{
    padding: 0 0.5rem;
    position: absolute;
    font-size: 7rem;
    top: 0;
    left: 10rem;
    height: 7rem;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #B3005E;
    background: #191825;
}
.projects__header__figure{
    padding: 0 0.5rem;
    position: absolute;
    top: 0;
    left: 10rem;
    height: 7rem;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #191825;
}
.projects__header__figure__img{
    height: 100%;
}
.projects__header__button{
    font-family: "Prompt", sans-serif;
    color: #E90064;
    font-size: 1.8rem;
    text-decoration: none;
    position: absolute;
    background: #191825;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 0.5rem;
}