.timeline{
    height: 100%;
    &::before{
        content: '';
        position: absolute;
        bottom: 50%;
        left: 50%;
        width: 2px;
        height: 3rem;
        background-color: #E90064;
        transform: translateX(-50%);
    }
    &--start{
        &::after{
            content: '';
            position: absolute;
            bottom: 50%;
            transform: translateY(50%);
            right: 0;
            height: 2px;
            width: 50%;
            background-color: #E90064;
        }
    }
    &--middle{
        &::after{
            content: '';
            position: absolute;
            bottom: 50%;
            transform: translateY(50%);
            left: 0;
            height: 2px;
            width: 100%;
            background-color: #E90064;
        }
    }
    &--end{
        &::after{
            content: '';
            position: absolute;
            bottom: 50%;
            transform: translateY(50%);
            left: 0;
            height: 2px;
            width: 50%;
            background-color: #E90064;
        }
    }
}