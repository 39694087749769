*,*:before,*:after{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html{
    font-size: 62.5%;
    @media screen and (max-width: 47rem) {
        font-size: 51.25%;
    }
}

body{
    font-size: 2rem;
    font-family: 'Roboto', sans-serif;
    background: #f5f7f9;
    min-height: 100vh;
}

.forcerender{
    position: absolute;
    width: 0;
    height: 0;
    pointer-events: none;
    opacity: 0;
    margin: 0;
    padding: 0;
}
